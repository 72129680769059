.swatch {

    &:focus:not(:hover) {
        box-shadow: none;
    }
}

.swatch:not(.swatch--square) .swatch__display {
    border-radius: 100%;
    &:after {
        border-radius: 100%;
    }
}

.swatch--bordered .swatch__display {
    border: 1px solid $c-border;

    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 3px solid $c-white;
    }
}

.swatch--multi {
    position: relative;
    display: block;
}

.swatch--empty,
.swatch--read-only {
    pointer-events: none;
}

    .swatch__display {
        display: block;
        width: 34px;
        height: 34px;
        border: 1px solid $c-white;
        cursor: pointer;

        &:focus:not(:hover) {
            box-shadow: none;
        }

        .swatch--basic & {
            position: relative;
            width: 26px;
            height: 26px;
            border: 1px solid $c-border;

            &:after {
                border-width: 2px;
            }
        }

        .swatch--medium & {
            width: 34px;
            height: 34px;
            &:after {
                border-width: 1px;
            }
        }

        .swatch--small & {
            width: 18px;
            height: 18px;
        }

        .swatch--tiny & {
            width: 17px;
            height: 17px;

            &:after {
                border-width: 1px;
            }
        }

        .swatch--multi & {
            position: relative;
            width: 148px;
            height: 148px;
            margin: 0 auto 10px auto;
            border: 1px solid $c-border;

            @include breakpoint(tablet) {
                width: 100px;
                height: 100px;
            }
            
            &:after {
                border-width: 4px;
            }
        }

        .swatch--basket &,
        .swatch--summary & {
            width: 100px;
            height: 100px;
            margin-bottom: 5px;
        }

        .swatch--basket & {

            @include breakpoint(tablet) {
                width: 40px;
                height: 40px;
                margin-bottom: 0;
            }

            @include breakpoint(tiny) {
                width: 34px;
                height: 34px;
            }

            &:after {

                @include breakpoint(tablet) {
                    border-width: 2px;
                }
            }
        }

        .swatch--empty & {
            border-color: $c-grey;
        }

        .swatch--reimagined & {
            position: relative;
            width: 50px;
            height: 50px;
            border: 1px solid $c-white;

            &:hover {
                border-color: $c-black;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 2px solid $c-white;
            }
        }

        .swatch--reimagined.swatch--small & {
            width: 38px;
            height: 38px;
        }

        input:focus + .swatch &,
        input:checked + .swatch &,
        .swatch--active &,
        .swatch:focus &, 
        .swatch:hover & {
            border: 1px solid $c-black;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 3px solid $c-white;
            }
        }

        input:focus + .swatch.swatch--basic &,
        input:checked + .swatch.swatch--basic &,
        .swatch.swatch--basic:focus &, 
        .swatch.swatch--basic:hover & {
            border: 1px solid $c-black;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 1px solid $c-white;
            }
        }

        input:focus + .swatch.swatch--multi &,
        input:checked + .swatch.swatch--multi &,
        .swatch.swatch--multi:focus &, 
        .swatch.swatch--multi:hover & {
            border: 1px solid $c-black;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 4px solid $c-white;
            }
        }

        input:focus + .swatch:not(.swatch--square) &,
        input:checked + .swatch:not(.swatch--square) &,
        .swatch--active:not(.swatch--square) &,
        .swatch:not(.swatch--square):focus &, 
        .swatch:not(.swatch--square):hover & {
            &:after {
                border: 1px solid $c-white;
            }
        }

        input:focus + .swatch--basic &,
        input:checked + .swatch--basic &,
        .swatch--basic.swatch--active &, 
        .swatch--basic.swatch--active:hover & 
        {
            border-color: $c-black;

            &:after {
                border-width: 1px;
            }
        }

        input:focus + .swatch--multi &,
        input:checked + .swatch--multi &,
        .swatch--multi.swatch--active &,
        .swatch--multi.swatch--active:hover & 
        {
            border-color: $c-black;

            &:after {
                border-width: 4px;
            }
        }

        input:focus + .swatch--basket &,
        input:checked + .swatch--basket &,
        .swatch--basket.swatch--active &, 
        .swatch--basket.swatch--active:hover & 
        {

            &:after {
                border-width: 2px;
            }
        }

        input:focus + .swatch--reimagined &,
        input:checked + .swatch--reimagined &,
        .swatch--active.swatch--reimagined &,
        .swatch--reimagined:focus & {
            &:after {
                border: 1px solid $c-white;
            }
        }
    }

    .swatch__text {
        font-size: 0;

        max-width: 148px;
        @include breakpoint(tablet) {
            max-width: 100px;
        }

        .swatch--multi & {
            @include font(16px, 22px);

            @include breakpoint(phablet) {
                @include font(14px, 22px);
            }

            text-align: center;
        }

        .swatch--basket & {
            @include body;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .swatch--summary & {
            @include body;
        }

        .swatch--empty & {
            color: $c-button--disabled;
        }

        .swatch--multi:hover &,
        input:focus + .swatch--multi &,
        input:checked + .swatch--multi &,
        .swatch--multi.swatch--active &
         {
            @include boldText;
        }
    }

    .swatch__status {
        display: none;
        position: absolute;
        top: 0;
        right: calc(50% - 70px);
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 33px;
        color: $c-black;
        background: $c-grey;
        border-radius: 100%;
        cursor: pointer;

        @include breakpoint(tablet) {
            width: 30px;
            height: 30px;
            right: calc(50% - 50px);
        }

        input:checked + .swatch--multi &,
        .swatch--multi.swatch--active &,
        .swatch--multi.swatch--active:hover & {
            display: flex;
            color: $c-white;
            background: $c-black;
        }

        .swatch--basket & {

            @include breakpoint(tablet) {
                width: 19px;
                height: 19px;
            }
        }

        .swatch--empty & {
            display: none;
        }

        svg {
            fill: currentColor;

            .swatch--basket & {

                @include breakpoint(tablet) {
                    max-width: 10px;
                }
            }
        }

        .icon-tick {
            display: none;

            input:checked + .swatch--multi &,
            .swatch--multi.swatch--active & {
                display: block;
            }

            input:checked + .swatch--multi:hover &,
            .swatch--multi.swatch--active:hover & {
                display: none;
            }
        }

        .icon-minus {
            display: none;	   
            input:checked + .swatch--multi:hover &,
            .swatch--multi.swatch--active:hover & {
                display: block;
            }
        }
    }

    .swatch--square.swatch--basic .swatch__display  {
        border: 1px solid $c-border;

        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid $c-white;
        }
    }

    .swatch--square.swatch--reimagined .swatch__display  {

        border: 1px solid $c-border;
        &:hover {
            border-color: $c-black;
        }

        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 2px solid $c-white;
        }
    }

    .swatch--square.swatch--reimagined.swatch--active .swatch__display  {
        border: 1px solid $c-black;
    }

    .swatch--square .swatch__status {
        z-index: 1;
        right: calc(50% - 84px);
        top: -11px;

        @include breakpoint(tablet) {
            right: calc(50% - 62px);
        }

        @include breakpoint(tiny) {
            right: calc(50% - 55px);
        }
    }

    .swatch--square .swatch__status.swatch__status--overlay {
        z-index: 1;
        right: calc(50% - 45px);
        top: -6px;
        width: 27px;
        height: 27px;

        @include breakpoint(tablet) {
            display: none !important;
        }

        // @include breakpoint(tiny) {
        //     right: calc(50% - 55px);
        // }

        .icon-tick {
            display: block !important;

            input:checked + .swatch--multi &,
            .swatch--multi.swatch--active & {
                display: block !important;
            }

            input:checked + .swatch--multi:hover &,
            .swatch--multi.swatch--active:hover & {
                display: block !important;
            }
        }

        .icon-minus {
            display: none !important;
        }
    }
