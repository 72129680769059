:root {
  --c-border: #000000;
  --c-panel-background: #FFFFFF;
  --c-disabled: #EEEEEE;
  --c-focus: #000000;
  --c-field-disabled: var(--c-disabled);
  --c-field-focus: var(--c-focus);
  --c-input-background: var(--c-panel-background);
  --c-input-border: var(--c-border);
}

.swatch:focus:not(:hover) {
  box-shadow: none;
}

.swatch:not(.swatch--square) .swatch__display {
  border-radius: 100%;
}
.swatch:not(.swatch--square) .swatch__display:after {
  border-radius: 100%;
}

.swatch--bordered .swatch__display {
  border: 1px solid #bdbdbd;
  position: relative;
}
.swatch--bordered .swatch__display:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px solid #FFFFFF;
}

.swatch--multi {
  position: relative;
  display: block;
}

.swatch--empty,
.swatch--read-only {
  pointer-events: none;
}

.swatch__display {
  display: block;
  width: 34px;
  height: 34px;
  border: 1px solid #FFFFFF;
  cursor: pointer;
}
.swatch__display:focus:not(:hover) {
  box-shadow: none;
}
.swatch--basic .swatch__display {
  position: relative;
  width: 26px;
  height: 26px;
  border: 1px solid #bdbdbd;
}
.swatch--basic .swatch__display:after {
  border-width: 2px;
}
.swatch--medium .swatch__display {
  width: 34px;
  height: 34px;
}
.swatch--medium .swatch__display:after {
  border-width: 1px;
}
.swatch--small .swatch__display {
  width: 18px;
  height: 18px;
}
.swatch--tiny .swatch__display {
  width: 17px;
  height: 17px;
}
.swatch--tiny .swatch__display:after {
  border-width: 1px;
}
.swatch--multi .swatch__display {
  position: relative;
  width: 148px;
  height: 148px;
  margin: 0 auto 10px auto;
  border: 1px solid #bdbdbd;
}
@media screen and (max-width: 62.4375em) {
  .swatch--multi .swatch__display {
    width: 100px;
    height: 100px;
  }
}
.swatch--multi .swatch__display:after {
  border-width: 4px;
}
.swatch--basket .swatch__display, .swatch--summary .swatch__display {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}
@media screen and (max-width: 62.4375em) {
  .swatch--basket .swatch__display {
    width: 40px;
    height: 40px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 23.75em) {
  .swatch--basket .swatch__display {
    width: 34px;
    height: 34px;
  }
}
@media screen and (max-width: 62.4375em) {
  .swatch--basket .swatch__display:after {
    border-width: 2px;
  }
}
.swatch--empty .swatch__display {
  border-color: #F0EEEB;
}
.swatch--reimagined .swatch__display {
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid #FFFFFF;
}
.swatch--reimagined .swatch__display:hover {
  border-color: #000000;
}
.swatch--reimagined .swatch__display:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #FFFFFF;
}
.swatch--reimagined.swatch--small .swatch__display {
  width: 38px;
  height: 38px;
}
input:focus + .swatch .swatch__display, input:checked + .swatch .swatch__display, .swatch--active .swatch__display, .swatch:focus .swatch__display, .swatch:hover .swatch__display {
  border: 1px solid #000000;
  position: relative;
}
input:focus + .swatch .swatch__display:after, input:checked + .swatch .swatch__display:after, .swatch--active .swatch__display:after, .swatch:focus .swatch__display:after, .swatch:hover .swatch__display:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px solid #FFFFFF;
}
input:focus + .swatch.swatch--basic .swatch__display, input:checked + .swatch.swatch--basic .swatch__display, .swatch.swatch--basic:focus .swatch__display, .swatch.swatch--basic:hover .swatch__display {
  border: 1px solid #000000;
  position: relative;
}
input:focus + .swatch.swatch--basic .swatch__display:after, input:checked + .swatch.swatch--basic .swatch__display:after, .swatch.swatch--basic:focus .swatch__display:after, .swatch.swatch--basic:hover .swatch__display:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #FFFFFF;
}
input:focus + .swatch.swatch--multi .swatch__display, input:checked + .swatch.swatch--multi .swatch__display, .swatch.swatch--multi:focus .swatch__display, .swatch.swatch--multi:hover .swatch__display {
  border: 1px solid #000000;
  position: relative;
}
input:focus + .swatch.swatch--multi .swatch__display:after, input:checked + .swatch.swatch--multi .swatch__display:after, .swatch.swatch--multi:focus .swatch__display:after, .swatch.swatch--multi:hover .swatch__display:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px solid #FFFFFF;
}
input:focus + .swatch:not(.swatch--square) .swatch__display:after, input:checked + .swatch:not(.swatch--square) .swatch__display:after, .swatch--active:not(.swatch--square) .swatch__display:after, .swatch:not(.swatch--square):focus .swatch__display:after, .swatch:not(.swatch--square):hover .swatch__display:after {
  border: 1px solid #FFFFFF;
}
input:focus + .swatch--basic .swatch__display, input:checked + .swatch--basic .swatch__display, .swatch--basic.swatch--active .swatch__display, .swatch--basic.swatch--active:hover .swatch__display {
  border-color: #000000;
}
input:focus + .swatch--basic .swatch__display:after, input:checked + .swatch--basic .swatch__display:after, .swatch--basic.swatch--active .swatch__display:after, .swatch--basic.swatch--active:hover .swatch__display:after {
  border-width: 1px;
}
input:focus + .swatch--multi .swatch__display, input:checked + .swatch--multi .swatch__display, .swatch--multi.swatch--active .swatch__display, .swatch--multi.swatch--active:hover .swatch__display {
  border-color: #000000;
}
input:focus + .swatch--multi .swatch__display:after, input:checked + .swatch--multi .swatch__display:after, .swatch--multi.swatch--active .swatch__display:after, .swatch--multi.swatch--active:hover .swatch__display:after {
  border-width: 4px;
}
input:focus + .swatch--basket .swatch__display:after, input:checked + .swatch--basket .swatch__display:after, .swatch--basket.swatch--active .swatch__display:after, .swatch--basket.swatch--active:hover .swatch__display:after {
  border-width: 2px;
}
input:focus + .swatch--reimagined .swatch__display:after, input:checked + .swatch--reimagined .swatch__display:after, .swatch--active.swatch--reimagined .swatch__display:after, .swatch--reimagined:focus .swatch__display:after {
  border: 1px solid #FFFFFF;
}

.swatch__text {
  font-size: 0;
  max-width: 148px;
}
@media screen and (max-width: 62.4375em) {
  .swatch__text {
    max-width: 100px;
  }
}
.swatch--multi .swatch__text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
  text-align: center;
}
@media screen and (max-width: 42.5em) {
  .swatch--multi .swatch__text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5714285714;
  }
}
.swatch--basket .swatch__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
@media screen and (max-width: 62.4375em) {
  .swatch--basket .swatch__text {
    display: none;
  }
}
.swatch--summary .swatch__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
.swatch--empty .swatch__text {
  color: #8d8d8d;
}
.swatch--multi:hover .swatch__text, input:focus + .swatch--multi .swatch__text, input:checked + .swatch--multi .swatch__text, .swatch--multi.swatch--active .swatch__text {
  font-weight: 700;
}

.swatch__status {
  display: none;
  position: absolute;
  top: 0;
  right: calc(50% - 70px);
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  color: #000000;
  background: #F0EEEB;
  border-radius: 100%;
  cursor: pointer;
}
@media screen and (max-width: 62.4375em) {
  .swatch__status {
    width: 30px;
    height: 30px;
    right: calc(50% - 50px);
  }
}
input:checked + .swatch--multi .swatch__status, .swatch--multi.swatch--active .swatch__status, .swatch--multi.swatch--active:hover .swatch__status {
  display: flex;
  color: #FFFFFF;
  background: #000000;
}
@media screen and (max-width: 62.4375em) {
  .swatch--basket .swatch__status {
    width: 19px;
    height: 19px;
  }
}
.swatch--empty .swatch__status {
  display: none;
}
.swatch__status svg {
  fill: currentColor;
}
@media screen and (max-width: 62.4375em) {
  .swatch--basket .swatch__status svg {
    max-width: 10px;
  }
}
.swatch__status .icon-tick {
  display: none;
}
input:checked + .swatch--multi .swatch__status .icon-tick, .swatch--multi.swatch--active .swatch__status .icon-tick {
  display: block;
}
input:checked + .swatch--multi:hover .swatch__status .icon-tick, .swatch--multi.swatch--active:hover .swatch__status .icon-tick {
  display: none;
}
.swatch__status .icon-minus {
  display: none;
}
input:checked + .swatch--multi:hover .swatch__status .icon-minus, .swatch--multi.swatch--active:hover .swatch__status .icon-minus {
  display: block;
}

.swatch--square.swatch--basic .swatch__display {
  border: 1px solid #bdbdbd;
  position: relative;
}
.swatch--square.swatch--basic .swatch__display:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #FFFFFF;
}

.swatch--square.swatch--reimagined .swatch__display {
  border: 1px solid #bdbdbd;
  position: relative;
}
.swatch--square.swatch--reimagined .swatch__display:hover {
  border-color: #000000;
}
.swatch--square.swatch--reimagined .swatch__display:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #FFFFFF;
}

.swatch--square.swatch--reimagined.swatch--active .swatch__display {
  border: 1px solid #000000;
}

.swatch--square .swatch__status {
  z-index: 1;
  right: calc(50% - 84px);
  top: -11px;
}
@media screen and (max-width: 62.4375em) {
  .swatch--square .swatch__status {
    right: calc(50% - 62px);
  }
}
@media screen and (max-width: 23.75em) {
  .swatch--square .swatch__status {
    right: calc(50% - 55px);
  }
}

.swatch--square .swatch__status.swatch__status--overlay {
  z-index: 1;
  right: calc(50% - 45px);
  top: -6px;
  width: 27px;
  height: 27px;
}
@media screen and (max-width: 62.4375em) {
  .swatch--square .swatch__status.swatch__status--overlay {
    display: none !important;
  }
}
.swatch--square .swatch__status.swatch__status--overlay .icon-tick {
  display: block !important;
}
input:checked + .swatch--multi .swatch--square .swatch__status.swatch__status--overlay .icon-tick, .swatch--multi.swatch--active .swatch--square .swatch__status.swatch__status--overlay .icon-tick {
  display: block !important;
}
input:checked + .swatch--multi:hover .swatch--square .swatch__status.swatch__status--overlay .icon-tick, .swatch--multi.swatch--active:hover .swatch--square .swatch__status.swatch__status--overlay .icon-tick {
  display: block !important;
}
.swatch--square .swatch__status.swatch__status--overlay .icon-minus {
  display: none !important;
}